<template>
  <b-container>
    <b-row
      ref="logoRow"
      class="title-row"
    >
      <b-col>
        <img
          v-if="org_code.toLowerCase() === `soit`"
          class="logo"
          alt="University of Cincinnati, School of Information Technology Logo"
          src="../assets/soit_logo.png"
        >
        <img
          v-else-if="org_code.toLowerCase() === `ocri`"
          class="logo"
          alt="University of Cincinnati, Ohio Cyber Range Institute"
          src="../assets/ocri_logo.png"
        >
        <span
          v-if="file"
          :class="{ 'pt-4': ![`soit`, `ocri`].includes(org_code.toLowerCase())}"
          class="d-flex align-items-center justify-content-between flex-wrap"
        >
          <!-- I hate this but it makes it center how I want -->
          <b-btn
            class="d-none d-md-block"
            aria-hidden="true"
            size="sm"
            variant="secondary"
            style="visibility: hidden;"
          >
            Download Accessible PDF
          </b-btn>
          <h1 tabindex="0">{{ file.name }}</h1>
          <b-btn
            size="sm"
            variant="secondary"
            :href="`/api/flipbook/${file.id}/download`"
          >
            Download Accessible PDF
          </b-btn>
        </span>
      </b-col>
    </b-row>
    <b-row aria-hidden="true">
      <b-col class="d-flex justify-content-center">
        <Flipbook
          v-if="file"
          :pages="orderBy(file.pages, `page_number`)"
          :ratio="file.size_ratio"
          :max-height="maxHeight"
          @size-changed="calculateFlipbookHeight"
        />
        <loader v-else />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { orderBy } from 'lodash';
import Flipbook from '@/components/Flipbook';
import { FileService, FlipbookService } from '@/services';

export default {
  name: `FlipbookWrapper`,
  components: {
    Flipbook,
  },
  data() {
    return {
      maxHeight: 0,
      file: null,
      org_code: this.$route.params.org_code || ``,
    };
  },
  async beforeCreate() {
    const { file_id, org_code, url } = this.$route.params;
    const flipbook = await FlipbookService.get({ org_code, url });
    if (file_id) {
      this.file = await FileService.getById({ id: file_id });
      this.org_code = this.file.organization.code;
      document.title = `${this.file.name} (Preview)`;
    } else if (flipbook) {
      this.file = flipbook.file;
      document.title = this.file.name;
    } else {
      this.$router.push(`/error/404?message=${encodeURI(`Cannot locate flipbook at "/${org_code}/${url}"`)}`);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateFlipbookHeight();
    });
  },
  methods: {
    calculateFlipbookHeight() {
      this.maxHeight = document.body.clientHeight - this.$refs.logoRow.clientHeight - $(`.footer`).height();
    },
    orderBy,
  },
};
</script>

<style lang="scss" scoped>
  .title-row {
    padding-bottom: 1rem;

    h1 {
      margin-bottom: 0;
    }

    .logo {
      height: 5rem;
      max-width: 100%;
      max-height: 100px;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
</style>
